import { useLocation, useNavigate } from 'react-router-dom';

export const partitionArray = <T>(arr: T[], predicate: (item: T) => boolean): [T[], T[]] => {
  const [trueArray, falseArray] = arr.reduce(
    (acc: [T[], T[]], item: T) => {
      if (predicate(item)) {
        acc[0].push(item);
      } else {
        acc[1].push(item);
      }
      return acc;
    },
    [[], []]
  );
  return [trueArray, falseArray];
};

export const percentageToFactor = (percentage: number) => (100 + percentage) / 100;
export const factorToPercentage = (factor: number) => (factor - 1) * 100;

export const useFetchJson = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return async <T>(url: string, options?: RequestInit): Promise<T> => {
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        navigate('/login?redirect=' + encodeURIComponent(location.pathname));
      }
      if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(`Failed to load data: ${errorBody.message}`);
      }
      return response.json();
    } catch (error) {
      throw error;
    }
  };
};
