import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

interface Project {
  /**
   * The generated id of the project
   */
  id: string;
  /**
   * Given number for the project
   */
  number: number;
  name: string;
  description: string;
  status: string;
  startDate: string;
}

const adapter = createEntityAdapter<Project>({
  selectId: (project) => project.id,
  sortComparer: (a, b) => b.number - a.number
});

export const projectSlice = createSlice({
  name: 'project',
  initialState: adapter.getInitialState({
    ids: ['1', '2', '3'],
    entities: {
      '1': {
        id: '1',
        number: 1,
        name: 'Project One',
        description: 'Description for Project One',
        status: 'Active',
        startDate: '2021-01-01'
      } as Project,
      '2': {
        id: '2',
        number: 2,
        name: 'Project Two',
        description: 'Description for Project Two',
        status: 'Active',
        startDate: '2021-02-01'
      } as Project,
      '3': {
        id: '3',
        number: 3,
        name: 'Project Three',
        description: 'Description for Project Three',
        status: 'Active',
        startDate: '2021-03-01'
      } as Project
    }
  }),
  reducers: {
    projectAdded: adapter.addOne,
    projectUpdated: adapter.setOne,
    projectRemoved: adapter.removeOne
  }
});

export const { projectAdded, projectUpdated, projectRemoved } = projectSlice.actions;

export default projectSlice.reducer;
