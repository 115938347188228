import React, { Ref, useCallback, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";

import { useQuery } from "@tanstack/react-query";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { setBundleAmount } from "./positionSlice";
import { useDispatch } from "react-redux";
import { CellValueChangedEvent, ColDef } from "ag-grid-community";

import { API_BASE } from "../../config";
import { useFetchJson } from "../../app/utils";

const StyledAgGrid = styled(AgGridReact<Cell[]>)`
  /* Hide the header */
  > .ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-header {
    // display: none;
  }

  --ag-cell-horizontal-border: 1px solid var(--ag-border-color);

  --ag-odd-row-background-color: var(--ag-background-color);
  --ag-row-hover-color: var(--ag-background-color);

  --ag-cell-horizontal-padding: 8px;
  --ag-input-focus-box-shadow: none;
`;

type InputSheetCell = {
  value: string;
  type: string;
} | null;

type Cell = {
  value: string;
  type: string;
  bundleId: string | null;
} | null;

export function InputGrid({ positionId, bundles }: { positionId: string; bundles: Record<string, number> }) {
  const gridElement: Ref<any> = useRef();

  const dispatch = useDispatch();
  const fetchJson = useFetchJson();

  const { isLoading, error, data } = useQuery({
    queryKey: ["inputSheetData"],
    staleTime: Infinity,
    queryFn: async () => {
      const inputSheetRowData: InputSheetCell[][] = await fetchJson(`${API_BASE}/inputsheet`, {
        credentials: "include",
      });

      const columnCount = inputSheetRowData.reduce((acc, row) => Math.max(acc, row.length), 0);
      if (!columnCount) {
        console.warn(`Inputsheet data does not contain columns`, inputSheetRowData);
      }

      const columnDefinitions: ColDef<Cell[]>[] = Array.from({ length: columnCount + 1 }).map((_, index) =>
        createAGGridColumnDef(index)
      );

      return { inputSheetRowData, columnDefinitions };
    },
  });

  useEffect(() => {
    // Position change will change the data in the grid
    // refesh the grid to update the view to match the data
    if (gridElement.current) {
      gridElement.current?.api?.refreshCells();
    }
  }, [positionId]);

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent<Cell[]>) => {
      const columnIndex = Number(event?.column?.getColId());
      const data = event.data?.[columnIndex];
      const rawNewValue: string | null = event?.newValue;
      const newValue = rawNewValue ? Number(rawNewValue) : null;

      const bundleId = data?.bundleId;
      console.log({ bundleId, newValue });
      if (!bundleId) return;

      dispatch(setBundleAmount({ id: positionId, bundleId: bundleId, amount: newValue ?? 0 }));
    },
    [dispatch, positionId]
  );

  const onCellKeyDown = useCallback((agEvent: any) => {
    const { api, event } = agEvent;

    const gridInEditMode = api.getEditingCells().length > 0;
    if (!gridInEditMode) return;

    if (event.code === "ArrowRight") {
      api.stopEditing();
      api.tabToNextCell();
    }
    if (event.code === "ArrowLeft") {
      api.stopEditing();
      api.tabToPreviousCell();
    }
    if (event.code === "ArrowDown") {
      const rowCount = api.getDisplayedRowCount();
      const lastRowIndex = rowCount - 1;

      const focusedCell = api.getFocusedCell();
      const targetRow = Math.min(lastRowIndex, focusedCell.rowIndex + 1);

      api.stopEditing();
      api.setFocusedCell(targetRow, focusedCell.column);
    }
    if (event.code === "ArrowUp") {
      const focusedCell = api.getFocusedCell();
      const targetRow = Math.max(0, focusedCell.rowIndex - 1);

      api.stopEditing();
      api.setFocusedCell(targetRow, focusedCell.column);
    }
  }, []);

  if (error) {
    if (error instanceof Error) {
      return ErrorCard("Failed to load inputsheet", error.message);
    }
    return ErrorCard("Failed to load inputsheet", `An error has occurred: ${String(error)}`);
  }

  if (!data || isLoading) return <progress className="progress is-small is-primary" max="100"></progress>;

  const { columnDefinitions, inputSheetRowData } = data;

  const rowData: Cell[][] = createAGRowData(bundles, inputSheetRowData);

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
      <StyledAgGrid
        rowHeight={30}
        ref={gridElement}
        rowData={rowData}
        columnDefs={columnDefinitions}
        onCellValueChanged={onCellValueChanged}
        onCellKeyDown={onCellKeyDown}
      ></StyledAgGrid>
    </div>
  );
}

function ErrorCard(title: string, message: string) {
  return (
    <article className="message is-danger">
      <div className="message-header">
        <p>{title}</p>
        <button className="delete" aria-label="delete"></button>
      </div>
      <div className="message-body">{message}</div>
    </article>
  );
}

/**
 * Combine bundles and inputSheetRowData into the AgGrid presentation format
 *
 * @param bundles
 * @param inputSheetRowData
 * @returns
 */
function createAGRowData(bundles: Record<string, number>, inputSheetRowData: InputSheetCell[][]) {
  return inputSheetRowData.map((row) => {
    return row.map((cell) => {
      if (!cell) return null;

      if (cell.type === "bundle") {
        const bundleId = cell.value;
        const amount = bundles[bundleId] ?? "";
        return {
          value: String(amount),
          type: "bundle",
          bundleId: bundleId,
        };
      }

      return {
        value: cell.value,
        type: cell.type,
        bundleId: null,
      };
    });
  });
}

function createAGGridColumnDef(columnIndex: number): ColDef<Cell[]> {
  return {
    valueGetter: (params) => {
      const cell = params?.data?.[columnIndex];
      if (!cell) return "";
      return cell.value;
    },
    valueSetter: (params) => {
      const cell = params.data[columnIndex];
      if (!cell) return false;

      if (cell.bundleId !== null) {
        cell.value = params.newValue;
        return true;
      }
      return false;
    },
    // @ts-expect-error cellRenderer is not typed, so defaulting to any
    cellRenderer: (params) => {
      const colId = params.column.colId;
      const data = params.data[colId];
      if (!data) return null;
      if (data.type === "title") return <b>{params.value}</b>;
      return params.value;
    },
    cellStyle: (params) => {
      const colId = Number(params.column.getColId());
      const data = params.data?.[colId];
      if (!data) {
        return { backgroundColor: "#F3F3F3" };
      }
      return null;
    },
    editable: (params) => {
      const colId = Number(params.column.getColId());
      const data = params.data?.[colId];
      if (!data) return false;
      return data.type === "bundle";
    },
    width: 100,
    resizable: true,
    suppressMovable: true,
    type: "rightAligned",
  };
}
