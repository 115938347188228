import React, { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import cn from "classnames";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_BASE } from "../config";
import { useFetchJson } from "../app/utils";
import classNames from "classnames";

export function NavBar() {
  return (
    <nav className="navbar is-hidden-print" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img alt="logo" src="/hewaco.png" />
        </Link>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <div className="navbar-item">
            <Breadcrumb />
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <User />
          </div>
        </div>
      </div>
    </nav>
  );
}

interface User {
  id: string;
  role: string;
  email: string;
}

const logout = async () => {
  const response = await fetch(`${API_BASE}/logout`, { method: "POST", credentials: "include" });
  if (response.ok) {
    return;
  }
  throw new Error("Unauthorized");
};

export function User() {
  const fetchJson = useFetchJson();
  const queryClient = useQueryClient();
  const [dropDownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = useCallback(() => {
    setDropdownOpen(!dropDownOpen);
  }, [dropDownOpen]);

  const query = useQuery({
    queryKey: ["user"],
    queryFn: () => {
      return fetchJson<User>(`${API_BASE}/me`, { credentials: "include" });
    },
  });

  const mutation = useMutation({
    mutationFn: logout,
    onSettled(data, error, variables, context) {
      console.log(data, error, variables, context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      window.location.href = "/login";
    },
  });

  const user = query.data;

  return (
    <div className="buttons">
      {user && (
        <>
          <div className={classNames("dropdown", "is-right", { "is-active": dropDownOpen })}>
            <div className="dropdown-trigger">
              <button className="button" aria-haspopup="true" aria-controls="dropdown-menu6" onClick={toggleDropDown}>
                <span>{user.email}</span>
              </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu6" role="menu">
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <p>
                    Email: {user.email} <br />
                    Role: {user.role} <br />
                  </p>
                </div>
                <hr className="dropdown-divider" />
                <button className="dropdown-item button is-text" onClick={() => mutation.mutate()}>
                  Log out
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {!user && (
        <>
          <a className="button is-primary" href="/login">
            <strong>Log in</strong>
          </a>
        </>
      )}
    </div>
  );
}

export function Breadcrumb() {
  const { projectId, positionId } = useParams();
  const project = useSelector((state: RootState) => projectId && state.projects.entities[projectId]);
  const position = useSelector((state: RootState) => positionId && state.positions.entities[positionId]);

  const depth = [project, position].filter((x) => x).length;

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/">Projects</Link>
        </li>
        {project && (
          <li className={cn({ "is-active": depth === 1 })}>
            <Link to={`/project/${projectId}`}>{project.name}</Link>
          </li>
        )}

        {project && position && (
          <li className={cn({ "is-active": depth === 2 })}>
            <Link to={`/project/${projectId}/position/${positionId}`}>{position.name}</Link>
          </li>
        )}
      </ul>
    </nav>
  );
}
