import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';

export interface Position {
  id: string;
  projectId: string;
  name: string;
  bundles: Record<string, number>;
  difficultyFactor?: number;
}

const adapter = createEntityAdapter<Position>({
  selectId: (position) => position.id,
});

export const positionSlice = createSlice({
  name: 'position',
  initialState: adapter.getInitialState({
    ids: ['1', '2', '3'],
    entities: {
      '1': {
        id: '1',
        projectId: '1',
        name: 'Position One',
        bundles: {}
      },
      '2': {
        id: '2',
        projectId: '1',
        name: 'Position Two',
        bundles: {}
      },
      '3': {
        id: '3',
        projectId: '1',
        name: 'Position Three',
        bundles: {}
      }
    }
  }),
  reducers: {
    positionAdded: adapter.addOne,
    positionUpdated: adapter.setOne,
    positionRemoved: adapter.removeOne,
    setBundleAmount: (
      state,
      action: PayloadAction<{ id: Position['id']; bundleId: string; amount: number }>
    ) => {
      const { id, bundleId, amount } = action.payload;
      if (state && state.entities && state.entities[id]) {
        // @ts-ignore
        state.entities[id].bundles[bundleId] = amount;
      }
    }
  }
});

export const { positionAdded, positionUpdated, positionRemoved, setBundleAmount } = positionSlice.actions;

export default positionSlice.reducer;
