import { API_BASE } from '../config';
import { EventStore, IEvent } from './EventStore';

export class EventSyncClient {
  sequenceTerm: number;
  eventStore: EventStore;

  constructor(eventStore: EventStore) {
    this.sequenceTerm = 0;
    this.eventStore = eventStore;
  }

  updateSequenceTerm(sequenceTerm: number) {
    this.sequenceTerm = sequenceTerm;
  }

  syncEvents = async (cb: (unsyncedEvents: IEvent[]) => void) => {
    const events = await this.eventStore.getLocalEvents();
    const locallyKnownServerSequenceTerm = await this.eventStore.getLatestKnownGlobalSequenceTerm();

    const { serverEvents } = await sendEventsToServer(events, locallyKnownServerSequenceTerm);

    if (serverEvents.length > 0) {
      await this.eventStore.updateLocalEvents(serverEvents);
    }

    const unsyncedEvents = await this.eventStore.getEvents(this.sequenceTerm);

    cb(unsyncedEvents);
  };

  addLocalEvent(userId: string, action: any) {
    return this.eventStore.addEvent(userId, action);
  }
}

export const sendEventsToServer = async (events: IEvent[], serverSequenceTerm: number) => {
  const clientId = 'TODO'; // one user can have many clients
  const response = await fetch(`${API_BASE}/events`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      events,
      clientId,
      globalSequenceTermKnownToClient: serverSequenceTerm
    })
  });

  if (response.status !== 200) {
    throw new Error('failed to send events to server');
  }

  const { serverEvents, localSequenceTermKnownToServer, globalSequenceTerm } =
    await response.json();
  return {
    localSequenceTermKnownToServer,
    serverSequenceTerm: globalSequenceTerm,
    serverEvents
  };
};
