"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorks = exports.getProducts = exports.unbundleSingle = exports.unbundle = void 0;
const unbundle = (register) => (bundleInputs) => {
    const mergedBundles = bundleInputs.reduce((acc, { bundleId, amount }) => {
        acc[bundleId] = acc[bundleId] === undefined ? amount : acc[bundleId] + amount;
        return acc;
    }, {});
    const bundles = Object.entries(mergedBundles).map(([bundleId, amount]) => {
        const bundle = register.Bundle[bundleId];
        if (bundle === undefined) {
            throw new Error(`Bundle with id ${bundleId} not found in register.`);
        }
        return { bundle, amount };
    });
    const workItems = bundles.flatMap(({ bundle, amount }) => {
        return bundle.items
            .filter((row) => row.kind === 'work')
            .map((row) => ({
            workId: row.id,
            amount: row.amount * amount
        }));
    });
    const productItems = bundles.flatMap(({ bundle, amount }) => {
        return bundle.items
            .filter((row) => row.kind === 'product')
            .map((row) => ({
            productId: row.id,
            amount: row.amount * amount,
            waste: row.waste
        }));
    });
    return {
        product: productItems,
        work: workItems
    };
};
exports.unbundle = unbundle;
const unbundleSingle = (register) => (bundleInput) => {
    return (0, exports.unbundle)(register)([bundleInput]);
};
exports.unbundleSingle = unbundleSingle;
const getProducts = (register) => (productItems) => {
    const mergedProducts = productItems.reduce((acc, { productId, amount }) => {
        acc[productId] = acc[productId] === undefined ? amount : acc[productId] + amount;
        return acc;
    }, {});
    return Object.entries(mergedProducts).map(([productId, amount]) => {
        const product = register.Product[productId];
        if (product === undefined) {
            throw new Error(`Product with id ${productId} not found in register.`);
        }
        return { product, amount };
    });
};
exports.getProducts = getProducts;
const getWorks = (register) => (workItems) => {
    const mergedWorks = workItems.reduce((acc, { workId, amount }) => {
        acc[workId] = acc[workId] === undefined ? amount : acc[workId] + amount;
        return acc;
    }, {});
    return Object.entries(mergedWorks).map(([workId, amount]) => {
        const work = register.Work[workId];
        if (work === undefined) {
            throw new Error(`Work with id ${workId} not found in register.`);
        }
        return { work, amount };
    });
};
exports.getWorks = getWorks;
