import React from "react";
import { API_BASE } from "./config";
import { useLocation, useNavigate } from "react-router-dom";

const login = (username: string, password: string) => {
  return fetch(`${API_BASE}/login/password`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
};

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loginError, setLoginError] = React.useState<string | null>(null);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { username, password } = Object.fromEntries(formData.entries() as IterableIterator<[string, string]>);
    const response = await login(username, password );
    if (response.ok) {
      const searchParams = new URLSearchParams(location.search);
      const redirectPath = searchParams.get('redirect') || '/';
      navigate(redirectPath);
    } else {
      console.log("Login failed", response);
      const errorBody = await response.json();
      setLoginError(errorBody.message || "Login failed");
    }
  };

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-one-quarter mt-6">
          <div className="box">
            <h1 className="is-size-2">Sign in</h1>

            <form className="mt-3" onSubmit={onSubmit}>
              <div className="field">
                <label className="label is-size-5" htmlFor="email">
                  Email
                </label>
                <div className="control">
                  <input
                    id="email"
                    name="username"
                    className="input"
                    type="email"
                    placeholder="e.g. alex@example.com"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-5" htmlFor="password">
                  Password
                </label>
                <div className="control">
                  <input id="password" name="password" className="input" type="password" placeholder="********" />
                </div>
              </div>

              <button className="button is-primary mt-3">Sign in</button>
              {loginError && <p className="has-text-danger mt-3">{loginError}</p>}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
