import React from "react";
import { Link, useParams } from "react-router-dom";
import { RootState } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { Position, positionAdded, positionRemoved, positionUpdated } from "./positionSlice";
import { nanoid } from "@reduxjs/toolkit";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { InputGrid } from "./InputGrid";
import styled from "styled-components";
import { factorToPercentage, percentageToFactor } from "../../app/utils";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MemoInputGrid = React.memo(InputGrid, (prevProps, nextProps) => {
  // ignore bundles for performance. The ag-grid will update the view itself on edit
  return prevProps.positionId === nextProps.positionId;
});

export function PositionList() {
  const { projectId, positionId } = useParams();

  const positions = useSelector((state: RootState) =>
    Object.values(state.positions.entities)
      .filter((position): position is Position => position !== undefined)
      .filter((position) => position.projectId === projectId)
  );
  const dispatch = useDispatch();

  if (!projectId) {
    return null;
  }

  const addNewPosition = () => {
    dispatch(positionAdded({ id: nanoid(), name: "New position", projectId: projectId, bundles: {} }));
  };

  return (
    <aside className="menu">
      <p className="menu-label">Project</p>
      <ul className="menu-list">
        <li>
          <NavLink to={`reports`} className={({ isActive }) => (isActive ? "is-active" : "")}>
            Report
          </NavLink>
        </li>
      </ul>
      <p className="menu-label">Positions</p>
      <ul className="menu-list">
        {positions.map((position) => (
          <li key={position.id}>
            <Link
              to={`position/${position.id}`}
              className={cn({ "is-active": positionId === position.id })}
            >
              {position.name}
            </Link>
          </li>
        ))}
        <li>
          <button className="button is-text" onClick={addNewPosition}>+ New position</button>
        </li>
      </ul>
    </aside>
  );
}

export function PositionView() {
  const { projectId, positionId } = useParams();
  const dispatch = useDispatch();

  const position = useSelector((state: RootState) => (positionId ? state.positions.entities[positionId] : undefined));

  if (!positionId || !position) {
    return null;
  }
  const removePosition = () => {

    dispatch(positionRemoved(positionId));
  };

  const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch(positionUpdated({ ...position, name: event.currentTarget.value }));
  };
  const onDifficultyFactorChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === "") {
      dispatch(positionUpdated({ ...position, difficultyFactor: undefined }));
      return;
    }

    const inputValue = Number(event.currentTarget.value);
    const difficultyFactor = isNaN(inputValue) ? undefined : percentageToFactor(inputValue);
    dispatch(positionUpdated({ ...position, difficultyFactor: difficultyFactor }));
  };

  const difficultyPercentage = position.difficultyFactor ? factorToPercentage(position.difficultyFactor).toFixed(0) : "";

  return (
    <Container>
      <div className="columns">
        <div className="column is-4">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label" htmlFor="name">
                Name
              </label>
            </div>
            <div className="field-body">
              <input id="name" className="input" type="text" value={position.name} onChange={onNameChange}></input>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label" htmlFor="difficulty-factor">
                Haittakerroin
              </label>
            </div>
            <div className="field-body">
              <input id="difficulty-factor" className="input" type="text" value={difficultyPercentage} onChange={onDifficultyFactorChange}></input>
            </div>
          </div>
        </div>
        <div className="column is-4 is-offset-4 has-text-right">
          <button className="button is-danger is-outlined" onClick={removePosition}>
            Delete Position
          </button>
        </div>
      </div>
      <MemoInputGrid positionId={positionId} bundles={position.bundles} />
    </Container>
  );
}
