import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { projectAdded } from "./projectSlice";
import { RootState } from "../../app/store";

import { EntityId, nanoid } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";

export function ProjectSummaryCard() {
  const { projectId } = useParams();

  return <div>ProjectSummaryCard: Project {projectId}</div>;
}

export function ProjectPage() {
  return (
    <div className="columns is-centered">
      <div className="column is-half mt-6">
        <div className="box">
          <ProjectList />
        </div>
      </div>
    </div>
  );
}

export function ProjectList() {
  const projects = useSelector((state: RootState) => state.projects);

  const dispatch = useDispatch();

  const lastProjectNumber = Object.values(projects.entities)[projects.ids.length - 1]?.number ?? 0;

  const addNewProject = () => {
    dispatch(
      projectAdded({
        id: nanoid(),
        number: lastProjectNumber + 1,
        name: "New project",
        description: "New project description",
        status: "New",
        startDate: "2021-01-01",
      })
    );
  };

  const projectElements = projects.ids.map((projectId) => <ProjectListItem key={projectId} projectId={projectId} />);

  return (
    <section className="posts-list">
      <div className="columns">
        <div className="column is-10">
          <h1 className="is-size-1">Projects</h1>
        </div>
        <div className="column is-2">
          <button className="button is-primary" onClick={addNewProject}>
            Add new project
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Number</th>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Start date</th>
          </tr>
        </thead>
        {projectElements}
      </table>
    </section>
  );
}

export function ProjectListItem({ projectId }: { projectId: EntityId }) {
  const navigate = useNavigate();
  const project = useSelector((state: RootState) => state.projects.entities[projectId]);
  if (!project) {
    return <div>Project not found</div>;
  }

  const handleClick = () => navigate(`project/${project.id}`);

  return (
    <tr onClick={handleClick}>
      <td>{project.number}</td>
      <td>{project.name}</td>
      <td>{project.description}</td>
      <td>{project.status}</td>
      <td>{project.startDate}</td>
    </tr>
  );
}
