"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMultipliers = void 0;
const HOURS_PER_DAY = 8;
const getMultipliers = (model) => {
    var _a, _b;
    const workMultiplier = model
        .filter((m) => m.costType === 'work')
        .reduce((acc, m) => {
        if (m.rule === 'currencyMultiplier') {
            acc.multiplier = m.amount;
        }
        if (m.rule === 'hourlyEarnings') {
            acc.daysPerUnit = acc.multiplier / (m.price * HOURS_PER_DAY);
        }
        if (m.rule === '%-increase') {
            acc.multiplier = acc.multiplier + (m.percentage / 100) * acc.multiplier;
        }
        if (m.rule === 'perDay') {
            acc.multiplier =
                acc.multiplier + acc.daysPerUnit * m.price * m.amount;
        }
        if (m.rule === 'margin-%') {
            acc.multiplier = acc.multiplier / ((100 - m.percentage) / 100);
        }
        return acc;
    }, { multiplier: 1, daysPerUnit: 1 });
    const materialsMultiplier = model
        .filter((m) => m.costType === 'material')
        .reduce((acc, m) => {
        if (m.rule === 'margin-%') {
            acc.multiplier = acc.multiplier / ((100 - m.percentage) / 100);
        }
        if (m.rule === '%-increase') {
            acc.multiplier = acc.multiplier + (m.percentage / 100) * acc.multiplier;
        }
        return acc;
    }, { multiplier: 1 });
    const normituntikerroin = (_a = model.find((m) => m.rule === 'currencyMultiplier')) === null || _a === void 0 ? void 0 : _a.amount;
    const kta = (_b = model.find((m) => m.rule === 'hourlyEarnings')) === null || _b === void 0 ? void 0 : _b.price;
    const workUnitsToHours = normituntikerroin / kta;
    return {
        work: workMultiplier.multiplier,
        material: materialsMultiplier.multiplier,
        workUnitsToHours
    };
};
exports.getMultipliers = getMultipliers;
