import { Middleware, Store, StoreEnhancer } from '@reduxjs/toolkit';
import { partitionArray } from './utils';
import { EventSyncClient } from './EventSyncClient';
import { DexieEventStore } from './DexieEventStore';
import { IEvent } from './EventStore';

const EVENT_SYNC_INTERVAL = 10_000; // 10s

export const createEventSyncSystem = () => {
  const eventStore = new DexieEventStore();
  const eventSyncer = new EventSyncClient(eventStore);

  const eventSyncEnhancer: StoreEnhancer = (createStore) => (reducer: any, initialState: any) => {
    // serverState is made only of events synced to server. Local events are not included.
    // the RESYNC_STATE includes both new server and local events.
    let serverState = initialState;

    const enhancedReducer = (state: any, action: any) => {
      if (action.type !== 'RESYNC_STATE') {
        return reducer(state, action);
      }

      // events here are any event that is not part of the serverState
      const { events } = action.payload;

      const [serverEvents, localEvents] = partitionArray(
        events,
        (event: IEvent) => event.globalSequenceTerm !== null
      );

      const unsyncedServerActions = serverEvents.map((event: IEvent) => event.action);
      serverState = unsyncedServerActions.reduce(reducer, serverState);

      if (serverEvents.length > 0) {
        const lastServerEvent = serverEvents[serverEvents.length - 1];
        eventSyncer.updateSequenceTerm(lastServerEvent.globalSequenceTerm as number); // partitionArray essures that globalSequenceTerm is number
      }

      const newState = localEvents.reduce(reducer, serverState);
      return newState;
    };

    const store = createStore(enhancedReducer, initialState);

    return store;
  };

  let syncStarted = false;
  const startEventSyncing = async (store: Store<any, any>) => {
    if (syncStarted) {
      return;
    }
    syncStarted = true;

    const handleUnsyncedEvents = (unsyncedEvents: IEvent[]) => {
      if (unsyncedEvents.length > 0) {
        store.dispatch({ type: 'RESYNC_STATE', payload: { events: unsyncedEvents } });
      }
    };
    setInterval(() => eventSyncer.syncEvents(handleUnsyncedEvents), EVENT_SYNC_INTERVAL);
    eventSyncer.syncEvents(handleUnsyncedEvents);
  };

  const eventSyncMiddleware: Middleware<{}, any> =
    (storeApi) => (next) => async (action) => {
      console.log(action);

      if (action.type === 'RESYNC_STATE') {
        return next(action);
      }

      const userId = 'todo';
      eventSyncer.addLocalEvent(userId, action);

      return next(action);
    };

  return {
    startEventSyncing,
    eventSyncEnhancer,
    eventSyncMiddleware
  };
};
