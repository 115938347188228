import { configureStore } from '@reduxjs/toolkit';
import positionReducer from '../features/position/positionSlice';
import projectReducer from '../features/project/projectSlice';
import { createEventSyncSystem } from './EventSyncSystem';

const { startEventSyncing, eventSyncEnhancer, eventSyncMiddleware } = createEventSyncSystem();

const store = configureStore({
  reducer: {
    positions: positionReducer,
    projects: projectReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(eventSyncMiddleware),
  enhancers: [eventSyncEnhancer]
});

const getStore = () => {
  startEventSyncing(store);

  return store;
};

export type RootState = ReturnType<typeof store.getState>;
export default getStore;
