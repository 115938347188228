import React from "react";
import "./App.css";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import styled from "styled-components";
import { Login } from "./Login";

import { ProjectPage } from "./features/project/Project";
import { PositionList, PositionView } from "./features/position/Position";
import { NavBar } from "./features/NavBar";
import { ProjectReport } from "./features/project/ProjectReport";

const FillHeightDiv = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

function App() {
  return (
    <FillHeightDiv>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<ProjectPage />} />
          <Route path="project/:projectId" element={<ProjectLayout />}>
            <Route path="reports" element={<ProjectReport />}></Route>
            <Route path="position/:positionId" element={<PositionView />}></Route>
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </FillHeightDiv>
  );
}

function Layout() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}

const SideBar = styled.div`
  max-width: 200px;
`;
const ContentArea = styled.div`
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  flex: 1 1 auto;
`;
const FlexGrow = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

function ProjectLayout() {
  return (
    <Wrapper className="columns m-1">
      <SideBar className="column is-2 is-hidden-print">
        <PositionList />
      </SideBar>
      <FlexGrow className="column">
        <ContentArea>
          <Outlet />
        </ContentArea>
      </FlexGrow>
    </Wrapper>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
export default App;
