import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import { groupBy, orderBy } from "lodash";

import { RootState } from "../app/store";
import { API_BASE } from "../config";
import { Product, Register, Work } from "shared/src/types";
import { BundleInput, getProducts, getWorks, unbundle } from "shared/src/unbundle";
import { CalclucationModelRule, getMultipliers } from "shared/src/calculationModel";
import { Position } from "../features/position/positionSlice";
import { factorToPercentage } from "../app/utils";

/*
Structure of the report data:

project->position->product->row
project->position->work->row
*/

const numberCellFormatting = "has-text-right";

export function Report(props: { scope: "PROJECT" | "POSITION" }) {
  const { projectId } = useParams();

  const project = useSelector((state: RootState) => state.projects.entities[projectId!]);

  const positions = useSelector((state: RootState) => {
    return Object.values(state.positions.entities).filter(
      (entity): entity is Position => entity?.projectId === projectId
    );
  });

  const results = useQueries({
    queries: [
      {
        queryKey: ["registers"],
        staleTime: Infinity,
        queryFn: async () => {
          const response = await fetch(`${API_BASE}/register`, { credentials: "include" });
          if (!response.ok) {
            throw new Error("Failed to load data registers");
          }
          const register: Register = await response.json();
          return register;
        },
      },
      {
        queryKey: ["calculation_model"],
        staleTime: Infinity,
        queryFn: async () => {
          const response = await fetch(`${API_BASE}/calculation_model`, { credentials: "include" });
          if (!response.ok) {
            throw new Error("Failed to load calculation model");
          }
          const calculationModel: CalclucationModelRule[] = await response.json();
          return calculationModel;
        },
      },
    ],
  });

  if (project === undefined) {
    return <div>Project not found</div>;
  }

  if (positions === undefined) {
    return <div>Project has no position</div>;
  }

  const [register, calculationModel] = [results[0].data, results[1].data];

  if (results.some((result) => result.isLoading)) {
    return <div>Loading...</div>;
  }
  if (!register) {
    return <div>Failed to load data registers</div>;
  }
  if (!calculationModel) {
    return <div>Failed to load calculation model</div>;
  }

  // Unbundle positions
  const bundles = positions.flatMap((position) => {
    return Object.entries(position!.bundles)
      .filter(([_, amount]) => !isNaN(amount) && amount > 0)
      .map(([bundleId, amount]) => ({ bundleId, amount }));
  });

  const { product, work } = unbundle(register)(bundles);
  const products = getProducts(register)(product);
  const works = getWorks(register)(work);

  return (
    <div>
      <h1 className="title">
        {project.name} (num: {project.number})
      </h1>
      <br />

      <PositionOverview positions={positions} calculationModel={calculationModel} register={register} />

      <PricingDetails calculationModel={calculationModel} />

      <MaterialsOverview products={products} />

      <PositionDetails positions={positions} register={register} calculationModel={calculationModel} />

      <WorkOverview works={works} />
    </div>
  );
}

interface ReportFragment {
  materialsListPrice: number;
  materialsNetPrice: number;
  materialsCost: number;
  workUnits: number; // includes difficulty factor
  workUnitsWithoutDifficulty: number;
  workHours: number;
  workCost: number;
  totalCost: number;
  effectiveDiscount: number;
}

const createPositionReportQuery = (register: Register, calculationModel: CalclucationModelRule[]) => {
  const multipliers = getMultipliers(calculationModel);

  const createReportFragment = (position: Position, bundles: BundleInput[]) => {
    const { product, work } = unbundle(register)(bundles);
    const products = getProducts(register)(product);
    const works = getWorks(register)(work);

    const totalListPrice = products.reduce((acc, { product, amount }) => acc + product.price * amount, 0);
    const totalNetPrice = products.reduce((acc, { product, amount }) => acc + product.netPrice * amount, 0);
    const effectiveDiscount = (1 - totalNetPrice / totalListPrice) * 100;
    const totalWorkUnitsWithoutDifficulty = works.reduce((acc, { work, amount }) => acc + work.units * amount, 0);
    const totalWorkUnitsWithDifficulty = totalWorkUnitsWithoutDifficulty * (position.difficultyFactor ?? 1);

    const quotePriceMaterials = totalNetPrice * multipliers.material;
    const workCost = totalWorkUnitsWithDifficulty * multipliers.work;
    const workHours = totalWorkUnitsWithDifficulty * multipliers.workUnitsToHours;

    const totalCost = quotePriceMaterials + workCost;

    const report: ReportFragment = {
      materialsListPrice: totalListPrice,
      materialsNetPrice: totalNetPrice,
      materialsCost: quotePriceMaterials,
      workUnits: totalWorkUnitsWithDifficulty,
      workUnitsWithoutDifficulty: totalWorkUnitsWithoutDifficulty,
      workHours,
      workCost,
      totalCost,
      effectiveDiscount,
    };
    return report;
  };

  return (position: Position) => {
    const bundles = Object.entries(position!.bundles)
      .filter(([_, amount]) => !isNaN(amount) && amount > 0)
      .map(([bundleId, amount]) => ({ bundleId, amount }));

    const positionReport = createReportFragment(position, bundles);

    const bundleDetails = bundles.map((bundle) => ({
      bundle,
      report: createReportFragment(position, [bundle]),
    }));

    const orderedBundles = orderBy(bundleDetails, (d) => d.bundle.bundleId);

    return {
      position,
      report: positionReport,
      bundles: orderedBundles,
    };
  };
};

function PositionOverview(props: {
  positions: Position[];
  calculationModel: CalclucationModelRule[];
  register: Register;
}) {
  const { calculationModel, positions, register } = props;

  const getPositionReportData = createPositionReportQuery(register, calculationModel);

  const positionData = positions.map(getPositionReportData);

  const totalCostAllPosition = positionData.reduce((acc, { report }) => acc + report.totalCost, 0);
  const totalWorkHoursAllPositions = positionData.reduce((acc, { report }) => acc + report.workHours, 0);

  return (
    <div className="has-page-break-after pb-6">
      <h1 className="title">Alatarjoukset ja positiot</h1>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <th>id</th>
          <th>Position</th>
          <th>Total list price</th>
          <th>Effective discount</th>
          <th>net price, materials</th>
          <th>Materiaalien hinta</th>

          <th>Total work units</th>
          <th>Tunnit</th>
          <th>Työn hinta</th>
          <th>Veroton hinta</th>
        </thead>
        <tbody>
          {positionData.map(
            ({
              position,
              report: {
                materialsListPrice,
                materialsNetPrice,
                materialsCost,
                workUnits,
                workHours,
                workCost,
                totalCost,
                effectiveDiscount,
              },
            }) => (
              <tr>
                <td>{position.id}</td>
                <td>{position.name}</td>
                <td className={numberCellFormatting}>{Number(materialsListPrice).toFixed(2)}</td>
                <td className={numberCellFormatting}>{Number(effectiveDiscount).toFixed(2)}</td>
                <td className={numberCellFormatting}>{Number(materialsNetPrice).toFixed(2)}</td>
                <td className={numberCellFormatting}>{Number(materialsCost).toFixed(2)}</td>

                <td className={numberCellFormatting}>{Number(workUnits).toFixed(2)}</td>
                <td className={numberCellFormatting}>{Number(workHours).toFixed(2)}</td>
                <td className={numberCellFormatting}>{Number(workCost).toFixed(2)}</td>
                <td className={numberCellFormatting}>{Number(totalCost).toFixed(2)}</td>
              </tr>
            )
          )}

          <tr>
            <td colSpan={5}>Yhteensä</td>
            <td></td>
            <td></td>
            <td className={numberCellFormatting}>{Number(totalWorkHoursAllPositions).toFixed(2)}</td>
            <td></td>
            <td className={numberCellFormatting}>{Number(totalCostAllPosition).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function PricingDetails(props: { calculationModel: CalclucationModelRule[] }) {
  const workRules = props.calculationModel.filter((rule) => rule.costType === "work");
  const materialRules = props.calculationModel.filter((rule) => rule.costType === "material");
  const multipliers = getMultipliers(props.calculationModel);

  return (
    <div className="has-page-break-after pb-6">
      <h1 className="title">Hinnanmuodostus</h1>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <th
            style={{
              width: "5%",
            }}
          >
            Kustannuslaji
          </th>
          <th>Nimi</th>
          <th>Sääntö</th>
          <th>%</th>
          <th>Hinta</th>
          <th>Määrä</th>
          <th>Vaikutus</th>
        </thead>
        <tbody>
          <tr>
            <td colSpan={6} className="has-text-weight-semibold">
              Työ
            </td>
          </tr>
          {workRules.map((row) => (
            <tr>
              <td></td>
              <td>{row.name}</td>
              <td>{row.rule}</td>
              <td className={numberCellFormatting}>{row.percentage && Number(row.percentage).toFixed(2)}</td>
              <td className={numberCellFormatting}>{row.price && Number(row.price).toFixed(2)}</td>
              <td className={numberCellFormatting}>{row.amount && Number(row.amount).toFixed(2)}</td>
            </tr>
          ))}

          <tr>
            <td colSpan={6} className="has-text-weight-semibold">
              Materiaali
            </td>
          </tr>
          {materialRules.map((row) => (
            <tr>
              <td></td>
              <td>{row.name}</td>
              <td>{row.rule}</td>
              <td className={numberCellFormatting}>{row.percentage && Number(row.percentage).toFixed(2)}</td>
              <td className={numberCellFormatting}>{row.price && Number(row.price).toFixed(2)}</td>
              <td className={numberCellFormatting}>{row.amount && Number(row.amount).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="table is-bordered">
        <thead>
          <th>Kustannuslaji</th>
          <th>Kerroin</th>
        </thead>
        <tr>
          <td>Työ</td>
          <td className={numberCellFormatting}>{Number(multipliers.work).toFixed(2)}</td>
        </tr>
        <tr>
          <td>Materiaali</td>
          <td className={numberCellFormatting}>{Number(multipliers.material).toFixed(2)}</td>
        </tr>
      </table>
    </div>
  );
}

function MaterialsOverview(props: { products: Array<{ product: Product; amount: number }> }) {
  const { products } = props;
  const sortedProducts = orderBy(products, ["category", "id"]);
  const productsByCategory = groupBy(sortedProducts, (product) => product.product.category);
  return (
    <div className="has-page-break-after pb-6">
      <h1 className="title">Tarvikelista</h1>

      <table className="table is-bordered is-fullwidth">
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Description</th>
          <th>Amount</th>
          <th>Price</th>
          <th>Net price</th>
          <th>Supplier</th>
          <th>Total price</th>
        </thead>
        <tbody>
          {Object.keys(productsByCategory)
            .sort()
            .map((category) => {
              const procuts = productsByCategory[category];
              const groupTotalPrice = procuts.reduce((acc, { product, amount }) => acc + amount * product.netPrice, 0);
              return (
                <>
                  {procuts.map(({ product, amount }) => (
                    <tr>
                      <td>{product.id}</td>
                      <td>{product.name}</td>
                      <td>{product.description}</td>
                      <td className={numberCellFormatting}>{Number(amount).toFixed(2)}</td>
                      <td className={numberCellFormatting}>{Number(product.price).toFixed(2)}</td>
                      <td className={numberCellFormatting}>{Number(product.netPrice).toFixed(2)}</td>
                      <td>{product.supplier}</td>
                      <td className={numberCellFormatting}>{Number(amount * product.netPrice).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={7}>Tuoteryhmä {category} yhteensä</td>
                    <td className={numberCellFormatting}>{Number(groupTotalPrice).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan={8}></td>
                  </tr>
                </>
              );
            })}
          <tr>
            <td colSpan={7}>Tuoteryhmät yhteensä</td>
            <td className={numberCellFormatting}>
              {Number(products.reduce((acc, { product, amount }) => acc + amount * product.netPrice, 0)).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function WorkOverview(props: { works: { work: Work; amount: number }[] }) {
  const { works } = props;
  return (
    <div className="has-page-break-after pb-6">
      <h1 className="title">Työt</h1>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Units</th>
          <th>group</th>
          <th>groupName</th>
          <th>row</th>
          <th>rowName</th>
          <th>Amount</th>
          <th>Total Units</th>
        </thead>
        <tbody>
          {works.map(({ work, amount }) => (
            <tr>
              <td>{work.id}</td>
              <td>{work.name}</td>
              <td>{work.units}</td>
              <td>{work.group}</td>
              <td>{work.groupName}</td>
              <td>{work.row}</td>
              <td>{work.rowName}</td>
              <td className={numberCellFormatting}>{amount.toFixed(2)}</td>
              <td className={numberCellFormatting}>{Number(amount * work.units).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function PositionDetails(props: {
  positions: Position[];
  register: Register;
  calculationModel: CalclucationModelRule[];
}) {
  const { positions, register, calculationModel } = props;

  const getPositionReportData = createPositionReportQuery(register, calculationModel);

  const positionData = positions.map(getPositionReportData);

  return (
    <div className="has-page-break-after pb-6">
      <h1 className="title">Laskelman rivit</h1>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <th>Pakettikoodi</th>
          <th>Nimi</th>
          <th>Tarkenne</th>
          <th>Amount</th>
          <th>Material net cost</th>
          <th>Work units</th>
          <th>Efektiivinen haittakerroin</th>
        </thead>
        <tbody>
          {positionData.map(
            ({ position, bundles, report: { materialsNetPrice, workUnits, workUnitsWithoutDifficulty } }) => {
              return (
                <>
                  <tr>
                    <td colSpan={6} className="has-text-weight-semibold">
                      {position.name}
                    </td>
                  </tr>
                  {bundles.map(({ bundle, report: bundleReport }) => (
                    <tr>
                      <td>{bundle.bundleId}</td>
                      <td>{register.Bundle[bundle.bundleId]?.name ?? "MISSING NAME"}</td>
                      <td>{register.Bundle[bundle.bundleId]?.description ?? "MISSING DESC"}</td>
                      <td className={numberCellFormatting}>{bundle.amount}</td>
                      <td className={numberCellFormatting}>{Number(bundleReport.materialsNetPrice).toFixed(2)}</td>
                      <td className={numberCellFormatting}>{Number(bundleReport.workUnits).toFixed(2)}</td>
                      <td className={numberCellFormatting}>
                        {Number(
                          factorToPercentage(bundleReport.workUnits / bundleReport.workUnitsWithoutDifficulty)
                        ).toFixed(0)}
                        %
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3}></td>
                    <td className="has-text-weight-semibold">{position.name} yhteensä</td>
                    <td className={numberCellFormatting}>{Number(materialsNetPrice).toFixed(2)}</td>
                    <td className={numberCellFormatting}>{Number(workUnits).toFixed(2)}</td>
                    <td className={numberCellFormatting}>
                      {Number(factorToPercentage(workUnits / workUnitsWithoutDifficulty)).toFixed(0)}%
                    </td>
                  </tr>
                </>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
}
