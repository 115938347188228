import Dexie from 'dexie';
import { EventStore, IEvent } from './EventStore';
import { v4 as uuidv4 } from 'uuid';

class LocalEventsDatabase extends Dexie {
  events!: Dexie.Table<IEvent, IEvent['eventId']>;

  constructor() {
    super('LocalEventsDatabase');
    this.version(2).stores({
      events: 'eventId,userId,globalSequenceTerm,localSequenceTerm,timestamp,actionType,action'
    });
  }
}

export class DexieEventStore implements EventStore {
  db: LocalEventsDatabase;
  localSequenceTerm: number | null = null;

  constructor() {
    this.db = new LocalEventsDatabase();
  }

  updateLocalEvents = async (serverEvents: IEvent[]) => {
    await this.db.events.bulkPut(serverEvents);
  };

  getEvents = async (sinceServerSequenceTerm: number) => {
    const events = await this.db.events
      .orderBy('localSequenceTerm')
      .filter(
        (event) =>
          event.globalSequenceTerm === null || event.globalSequenceTerm > sinceServerSequenceTerm
      )
      .toArray();

    return events;
  };

  getLocalEvents = async () => {
    const events = await this.db.events
      .orderBy('localSequenceTerm')
      .filter((event) => event.globalSequenceTerm === null)
      .toArray();

    return events;
  };

  getLatestKnownGlobalSequenceTerm = async () => {
    const result = await this.db.events.orderBy('globalSequenceTerm').last();
    return result?.globalSequenceTerm || 0;
  };

  addEvent = async (userId: string, action: any) => {
    if (this.localSequenceTerm === null) {
      this.localSequenceTerm =
        (await this.db.events.orderBy('localSequenceTerm').last())?.localSequenceTerm || 0;
    }

    const event: IEvent & { actionType: string } = {
      eventId: uuidv4(),
      userId,
      clientId: 'TODO', // one user can have many clients
      globalSequenceTerm: null, // location in the global sequence. null for unsynced items
      localSequenceTerm: ++this.localSequenceTerm,
      timestamp: new Date(),
      actionType: action.type,
      action: action
    };

    await this.db.events.add(event);
  };
}
